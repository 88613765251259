import Vue from 'vue';
import VueRouter from 'vue-router';
import AreaDetail from '../views/area-detail.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'AreaDetail',
    component: AreaDetail
  }
];

const router = new VueRouter({
  routes
});

export default router;
