import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/css/base.css';
import axios from 'axios';
import VueAxios from 'vue-axios';
import {
  Drawer,
  Dialog,
  Button,
  Image,
  Carousel,
  CarouselItem,
  Select,
  Option,
  Radio,
  Checkbox,
  CheckboxGroup,
  Switch
} from 'element-ui';
Vue.use(VueAxios, axios);
Vue.config.productionTip = false;
Vue.use(Drawer)
  .use(Dialog)
  .use(Button)
  .use(Image)
  .use(Carousel)
  .use(CarouselItem)
  .use(Select)
  .use(Option)
  .use(Radio)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Switch);
Vue.use(axios);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
