<template>
  <div id="area-detail">
    <!-- <div id="web-title">建筑红楼</div> -->
    <div id="page-content" class="page-content"></div>

    <!-- 地区按钮 -->
    <!-- <area-list-vue @on-change-list="handleChangeList" v-model="isActive" /> -->

    <!-- 剑川简介 -->
    <!-- <transition name="fade">
      <area-intro-vue
        v-if="isActive == 1"
        @onCloseIntroClick="handleCloseIntroClick"
      />
    </transition> -->

    <!-- pc弹窗 -->
    <!-- <model-relic-dialog-vue
      :relicDetail="relicDetail"
      v-model="showDetail"
      v-if="!isPhone && showDetail"
    /> -->
    <!-- 手机弹窗 -->
    <!-- <phone-model-dialog-vue
      :relicDetail="relicDetail"
      v-model="showDetail"
      v-if="isPhone && showDetail"
    /> -->
    <!-- 筛选弹窗 -->
    <!-- <relic-filter-drawer-vue
      v-model="isActive"
      @onTypeSelect="handleTypeSelect"
      @onChangeVisiblePoint="handleChangeVisiblePoint"
      :relicList="relicList"
      @onSearchRelic="handleSearchRelic"
      :drawerSize="drawerSize"
    /> -->
    <!-- <div id="web-company">
      <div>主办：剑川县住房和城乡建设局</div>
      <div>承办：广州方舆科技有限公司</div>
    </div> -->
  </div>
</template>
<script>
// import areaListVue from '../components/area-list.vue';
// import areaIntroVue from '../components/area-intro.vue';
// import modelRelicDialogVue from '../components/model-relic-dialog.vue';
// import relicFilterDrawerVue from '../components/relic-filter-drawer.vue';
// import phoneModelDialogVue from '../components/phone-model-dialog.vue';
import staticData from '../static/data.json'
export default {
  name: 'AreaDetail',
  components: {
    // areaListVue,
    // areaIntroVue,
    // modelRelicDialogVue,
    // relicFilterDrawerVue,
    // phoneModelDialogVue
  },
  computed: {
    drawerSize() {
      if (this.isPhone) {
        return '80vw';
      } else {
        return '380px';
      }
    }
  },
  data() {
    return {
      isPhone: false,
      textTagList: [],
      typeList: [],
      visiblePoint: false,
      relicList: staticData.data.villages.jianchuan.pose,
      isActive: 1,
      showDetail: false,
      relicDetail: null,
      data: {
        pose:[
          {
              "name": "建筑红楼",
              "lng": 2.812851933000933,
              "lat": 2.8123549793845055,
              "alt": 29.689548061385977,
              "vr": "https://show.3dnest.cn/work/index.html?work_id=88ZwbmYAKl9"
          },
          {
              "name": "建筑工程系馆旧址",
              "lng": 2.812339952809844,
              "lat": 2.8125896288156205,
              "alt": 29.458427354974063,
              "vr": "https://show.3dnest.cn/work/index.html?work_id=FOYdoIsTxtr"
          },
          {
              "name": "建筑设计研究院",
              "lng": 2.812374668967744,
              "lat": 2.8121133814993313,
              "alt": 28.556058931825728,
              "vr": "https://show.3dnest.cn/work/index.html?work_id=Lovdyq2u0c7"
          }
        ]
      },
      sandbox: null,
      options: {
        altizureApi: {
          key: 'qwKCxUxTywiAlenHoNfhVzRuGA76O7EEYPyC4Av'
        },
        camera: {
          center: {
            alt: 240.5166400336488,
            lng: 2.8125,
            lat: 2.8125,
            north: 270.1520750526322,
            tilt: 35.32768659912247,
            up: 0
          }
        },
        renderItems: {
          earth: false,
          earthUseTexture: false,
          featureInView: false,
          orbitRing: true // 不显示初始地球的框架
        }
      }
    };
  },
  created() {
    this.isPhone = this.isMobile();
  },
  mounted() {
    /**
     * 查看当前的坐标信息
     * sandbox.camera.center
     */
    this.initAltizure(this.data)
  },
  methods: {
    isMobile() {
      const mobileArry = [
        'iPhone',
        'iPad',
        'Android',
        'Windows Phone',
        'BB10; Touch',
        'BB10; Touch',
        'PlayBook',
        'Nokia'
      ];
      const ua = navigator.userAgent;
      const res = mobileArry.filter(function(arr) {
        return ua.indexOf(arr) > 0;
      });
      return res.length > 0;
    },
    handleCloseIntroClick() {
      this.isActive = 7;
    },
    /**
     * 监听点击侧边栏回调
     */
    handleChangeList() {
      this.showDetail = false;
    },
    /**
     * 初始化模型
     */
    initAltizure(data) {
      /* eslint-disable */
      this.sandbox = new altizure.Sandbox('page-content', this.options);
      window.sandbox = this.sandbox;
      this.sandbox
        .add('AltizureProjectMarker', {
          pid: '64730c1ac8e9fa476df98570',
          pose: {
            position: {
              lng: 2.8125,
              lat: 2.8125,
              alt: 5.198364013650895
            }
          }
        })
        .then(model => {
          model.decoHolder.visible = false; //隐藏蓝边
          model.interactable = true;
          // this.sandbox.marker.loadCropMask();
          // model.on('click', e => {
          //   let { x, y } = { x: e.offsetX, y: e.offsetY };
          //   console.log(position);
          //   console.log(this.sandbox.window.toLngLatAlt);
          //   let pos = this.sandbox.window.toLngLatAlt({ x, y });
          //   console.log(pos, 111);
          // });

          // this.initPolygon(data.poly1, 0x7f0000);
          // this.initPolygon(data.poly2, 0x9900cc);
          // this.initPolygon(data.poly3, 0x992600);
          // this.initPolygon(data.poly4, 0x9900cc);
          // this.initPolygon(data.poly5, 0x992600);
          data.pose.forEach((e, index) => {
            let { alt, lat, lng } = e;
            e.position = { alt, lat, lng };
            e.id = index;
            let tagParam = {
              name: e.name,
              position: e.position,
              type: e.type,
              number: e.number,
              id: e.id
            };
            let textTag = this.addTextTag(tagParam);
            this.textTagList.push(textTag);
            textTag.on('click', res => {
              let camera = this.sandbox.camera;
              camera.lookAt(textTag, 0, 0, 0, 2000).then(() => {
                camera
                  .flyTo(
                    {
                      alt: textTag.position.alt + 15,
                      lng: camera.pose.lng,
                      lat: camera.pose.lat,
                      north: camera.pose.north,
                      tilt: 0,
                      up: camera.pose.up
                    },
                    1000
                  )
                  .then(() => {
                    if(e.vr.length){
                      window.location.href = e.vr
                    }
                    // this.relicDetail = e;
                    // this.showDetail = true;
                  });
              });
            });
          });
          return model.loadCropMask();
        })
        .catch(err => {
          // console.log(err);
        });
    },
    /**
     * 添加mark
     */
    addTextTag(param) {
      let sandbox = this.sandbox;
      const nameLength = param.name.length;
      let w = 0,
        h = 70;
      if (nameLength > 3) {
        w = 50 * nameLength;
      } else {
        w = 150;
      }
      let textTag = new altizure.CanvasTagMarker({
        pinLength: 10,
        // icon position
        position: param.position,
        // scene
        earth: sandbox,
        // scale: 0.015, // icon size
        size: {
          width: w,
          height: h
        },
        interactable: true,
        fixedSize: 50
      });
      textTag.name = param.name + param.lng;
      let ctx = textTag.context;

      ctx.fillStyle = 'rgba(152, 83, 66)';
      ctx.fillRect(0, 0, w, h);
      ctx.fillStyle = 'rgba(255,255,255)';
      ctx.font = '40px 微软雅黑';
      ctx.textAlign = 'center';
      ctx.fillText(param.name, w / 2, 50);
      textTag.pin.pinHead.visible = false;
      textTag.pinColor = new THREE.Color('#985342');
      return textTag;
    },
    /**
     * 关闭详情
     */
    handleCloseDetail() {
      this.showDetail = false;
    },
    /**
     * 点击列表显示详情
     */
    handleShowDetail(index) {
      this.relicDetail = this.data.pose[index];
      this.showDetail = true;
    },
    /**
     * 初始化区域范围
     */
    initPolygon(poly, color) {
      let sandbox = this.sandbox;
      poly.map(item => {
        item.lng = item.lng * 1;
        item.lat = item.lat * 1;
        item.alt = item.alt * 1;
      });
      let pl3 = new altizure.PolyLineMarker({
        name: 'pl3',
        sandbox: sandbox,
        points: poly,
        color,
        fenceHeight: 10
      });
    },

    /**
     * 监听类型筛选
     */
    handleTypeSelect(newValue) {
      this.typeList = newValue;
      const { pose } = this.data;
      this.relicList = pose.filter(item => {
        if (this.visiblePoint) {
          return item.pointCloudUrl;
        } else {
          return true;
        }
      });
      this.relicList = this.relicList.filter(item => {
        if (newValue.length == 0) {
          return item;
        } else {
          return newValue.some(value => {
            return value == item.type;
          });
        }
      });
      this.addCheckedList(this.relicList);
    },
    /**
     * 监听point筛选
     */
    handleChangeVisiblePoint(value) {
      this.visiblePoint = value;
      const { pose } = this.data;
      this.relicList = pose.filter(item => {
        if (this.typeList.length == 0) {
          return item;
        } else {
          return this.typeList.some(value => {
            return value == item.type;
          });
        }
      });
      this.relicList = this.relicList.filter(item => {
        if (value) {
          return item.pointCloudUrl;
        } else {
          return true;
        }
      });
      this.addCheckedList(this.relicList);
    },

    /**
     * 筛选添加时间
     * @param {Array} data 筛选数组
     */
    addCheckedList(data) {
      this.textTagList.forEach(marker => {
        marker.destruct();
      });
      this.textTagList = [];
      data.forEach(e => {
        let tagParam = {
          name: e.name,
          position: e.position,
          type: e.type,
          number: e.number
        };
        let textTag = this.addTextTag(tagParam);
        this.textTagList.push(textTag);
        textTag.on('click', res => {
          let camera = this.sandbox.camera;
          camera.lookAt(textTag, 0, 0, 0, 2000).then(() => {
            camera
              .flyTo(
                {
                  alt: textTag.position.alt + 15,
                  lng: camera.pose.lng,
                  lat: camera.pose.lat,
                  north: camera.pose.north,
                  tilt: 0,
                  up: camera.pose.up
                },
                1000
              )
              .then(() => {
                this.relicDetail = e;
                this.showDetail = true;
              });
          });
        });
      });
    },

    /**
     * 搜索选中调转镜头显示文物回调
     * @param {object} data 选中文物回调
     */
    handleSearchRelic(data) {
      const textTag = this.textTagList.find(item => {
        return item.position.lng === data.lng;
      });
      let camera = this.sandbox.camera;
      camera.lookAt(textTag, 0, 0, 0, 2000).then(() => {
        camera
          .flyTo(
            {
              alt: data.position.alt + 15,
              lng: camera.pose.lng,
              lat: camera.pose.lat,
              north: camera.pose.north,
              tilt: 0,
              up: camera.pose.up
            },
            1000
          )
          .then(() => {
            this.relicDetail = data;
            this.showDetail = true;
          });
      });
    }
  }
};
</script>

<style lang="scss">
#area-detail {
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 1s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .start-enter-active,
  .start-leave-active {
    transition: height 0.5s;
  }
  .start-enter, .start-leave-to /* .fade-leave-active below version 2.1.8 */ {
    height: 0;
  }
  #web-title {
    position: fixed;
    z-index: 200;
    top: 10px;
    right: 0;
    width: 310px;
    max-width: 100vw;
    height: 47px;
    text-align: center;
    line-height: 47px;
    background-color: $primary-light;
    color: rgb(255, 255, 255);
  }
  #web-company {
    position: fixed;
    z-index: 50;
    bottom: 0;
    right: 0;
    width: 282px;
    max-width: 100vw;
    height: 51px;
    font-size: 12px;
    background-color: rgba($color: #666, $alpha: 0.5);
    color: #fff;
    div {
      margin: 10px 5px;
    }
  }
  .page-content {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #000;
    z-index: 0;
  }
  @media screen and (max-width: 960px) {
    #web-title {
      top: 0;
      width: 100vw;
    }
    #web-company {
      width: 100vw;
      text-align: center;
      div {
        margin: 5px 5px;
      }
    }
  }
}
</style>
